/* eslint-disable no-param-reassign */
const initialState = {
  connected: true,
  authenticated: false,
  token: null
};

const authenticated = (state, payload) => {
  state = Object.assign({}, state, {
    authenticated: true,
    token: payload.token
  });
  return state;
};
const unauthenticated = state => {
  state = Object.assign({}, state, {
    authenticated: false,
    token: null
  });
  return state;
};
const online = state => {
  state = Object.assign({}, state, {
    connected: true
  });
  return state;
};
const offline = state => {
  state = Object.assign({}, state, {
    connected: false
  });
  return state;
};

const System = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case 'SYSTEM_ONLINE':
      return online(state, payload);
    case 'SYSTEM_OFFLINE':
      return offline(state);
    case 'SYSTEM_AUTHENTICATED':
      return authenticated(state, payload);
    case 'SYSTEM_UNAUTHENTICATED':
      return unauthenticated(state);
    default:
      return state;
  }
};

export default System;
