/* eslint-disable no-param-reassign */

function Persisted(state, payload) {
  state = Object.assign({}, state, payload);
  return state;
}

const reducer = (state = {}, { type, payload = null }) => {
  switch (type) {
    case 'persist/REHYDRATE':
      return Persisted(state, payload);
    default:
      return state;
  }
};

export default reducer;
