/* eslint-disable no-param-reassign */
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import Persisted from '../Reducers/Persisted';
import System from '../Reducers/System';

const appReducer = combineReducers({
  System,
  Persisted
});

const RootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  whitelist: ['System', 'Cart', 'Order']
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));

export const Redux = {
  Provider,
  Gate: PersistGate
};

export const persistor = persistStore(store);
export default store;
