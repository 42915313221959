import React from 'react';

import App, { Container } from 'next/app';

import NProgress from 'nprogress';
import Router from 'next/router';

import store, { Redux } from '../Services/Store';
import './_app.scss';
import sentry from '../Services/Monitor';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const { Sentry, captureException } = sentry();

export default class MyApp extends App {
  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.state = {
      hasError: false,
      errorEventId: undefined
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    document.body.classList.remove('no-js');
    import('../Services/Bootstrap');
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });
    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return this.state.hasError ? (
      <section>
        <h1>There was an error!</h1>
        <p>
          <button
            type="button"
            onClick={() => Sentry.showReportDialog({ eventId: this.state.errorEventId })}
          >
            Report this error
          </button>
        </p>
        <p>
          <button
            type="button"
            onClick={() => {
              // eslint-disable-next-line no-undef
              window.location.reload(true);
            }}
          >
            Or, try reloading the page
          </button>
        </p>
      </section>
    ) : (
      // Render the normal Next.js page
      <Container>
        <Redux.Provider store={store}>
          <Component {...pageProps} />
        </Redux.Provider>
      </Container>
    );
  }
}
